import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b0e9db4c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content-item"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "text"
};
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = {
  class: "price"
};
import { reactive } from 'vue';
export default {
  __name: 'ListItem',
  props: {
    itemContent: {
      type: Object,
      require: true
    },
    handleAdd: {
      type: function () {}
    },
    handleChange: {
      type: function () {}
    },
    showCheckBox: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    const props = __props;

    /* eslint-disable */
    // eslint-disable-next-line vue/no-setup-props-destructure

    const data = reactive({
      num: props.itemContent.num
    });
    return (_ctx, _cache) => {
      const _component_van_checkbox = _resolveComponent("van-checkbox");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_stepper = _resolveComponent("van-stepper");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [__props.showCheckBox ? (_openBlock(), _createBlock(_component_van_checkbox, {
        key: 0,
        name: __props.itemContent.id,
        "checked-color": "#ffc400"
      }, null, 8, ["name"])) : _createCommentVNode("", true), _createElementVNode("img", {
        src: __props.itemContent.pic
      }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(__props.itemContent.title), 1), __props.itemContent.add ? (_openBlock(), _createBlock(_component_van_icon, {
        key: 0,
        name: "add-o",
        onClick: _cache[0] || (_cache[0] = $event => __props.handleAdd(__props.itemContent.id))
      })) : (_openBlock(), _createBlock(_component_van_stepper, {
        key: 1,
        modelValue: data.num,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => data.num = $event),
        onChange: __props.handleChange,
        name: __props.itemContent.id
      }, null, 8, ["modelValue", "onChange", "name"]))])]), _createElementVNode("div", _hoisted_6, "￥" + _toDisplayString(__props.itemContent.price), 1)]);
    };
  }
};